//Install
import React from "react";
//Install
import "swiper/css";
//Install
import "swiper/css/navigation";
//Install
import "swiper/css/pagination";
//Install
import "bootstrap/dist/css/bootstrap.min.css";
//Install
import "./App.css";
//Install
import "./Responsive.css";
//Install
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
//Install
import Header from "./Component/Header";
//Install
import Main from "./Component/Main";
//Install
import Corporation from "./Component/Tab/Corporation/Showcase";
//Install
import Founder from "./Component/Tab/Founder/Default";
//Install
import About from "./Component/Tab/About/Default";
//Install
import Value from "./Component/Tab/Value/Default";
//Install
import Board from "./Component/Tab/Board/Default";
//Install
import Sector from "./Component/Tab/Sector/Showcase";
//Install
import Building from "./Component/Tab/Sector/Building";
//Install
import Tourism from "./Component/Tab/Sector/Tourism";
//Install
import Food from "./Component/Tab/Sector/Food";
//Install
import Factory from "./Component/Tab/Sector/Factory";
//Install
import Sport from "./Component/Tab/Sector/Sport";
//Install
import Tech from "./Component/Tab/Sector/Tech";
//Install
import Responsibility from "./Component/Tab/Responsibility/Showcase";
//Install
import Future from "./Component/Tab/Responsibility/Default";
//Install
import Done from "./Component/Tab/Responsibility/Done";
//Install
import Art from "./Component/Tab/Responsibility/Art";
//Install
import History from "./Component/Tab/Responsibility/History";
//Install
import Sports from "./Component/Tab/Responsibility/Sport";
//Install
import Child from "./Component/Tab/Responsibility/Child";
//Install
import Young from "./Component/Tab/Responsibility/Young";
//Install
import Women from "./Component/Tab/Responsibility/Women";
//Install
import Society from "./Component/Tab/Responsibility/Society";
//Install
import Career from "./Component/Tab/Career/Showcase";
//Install
import Recruitment from "./Component/Tab/Career/Recruitment";
//Install
import Opportunities from "./Component/Tab/Career/Opportunities";
//Install
import Values from "./Component/Tab/Career/Value";
//Install
import Social from "./Component/Tab/Career/Social";
//Install
import Working from "./Component/Tab/Career/Working";
//Install
import Education from "./Component/Tab/Career/Education";
//Install
import Performance from "./Component/Tab/Career/Performance";
//Install
import Safety from "./Component/Tab/Career/Safety";
//Install
import Price from "./Component/Tab/Career/Price";
//Install
import Ethics from "./Component/Tab/Career/Ethics";
//Install
import News from "./Component/Tab/News/Default";
//Install
import Article from "./Component/Tab/News/Article";
//Install
import Sustainability from "./Component/Tab/Sustainability/Default";
//Install
import Search from "./Component/Tab/Search/Default";
//Install
import Contact from "./Component/Tab/Contact/Default";
//Install
import Footer from "./Component/Footer";

//Element
function App() {
  //Element
  return (
    //Element
    <div className="App">
      <Header></Header>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={ <Main /> }></Route>
          <Route path="/kurumsal" element={ <Corporation /> }></Route>
          <Route path="/kurucumuz" element={ <Founder /> }></Route>
          <Route path="/hakkimizda" element={ <About /> }></Route>
          <Route path="/kurumsal-degerler" element={ <Value /> }></Route>
          <Route path="/yonetim-kurulu" element={ <Board /> }></Route>
          <Route path="/faaliyet-alanlari" element={ <Sector /> }></Route>
          <Route path="/sirket/insaat" element={ <Building /> }></Route>
          <Route path="/sirket/turizm" element={ <Tourism /> }></Route>
          <Route path="/sirket/gida" element={ <Food /> }></Route>
          <Route path="/sirket/endustri" element={ <Factory /> }></Route>
          <Route path="/sirket/spor" element={ <Sport /> }></Route>
          <Route path="/sirket/teknoloji" element={ <Tech /> }></Route>
          <Route path="/surdurulebilirlik" element={ <Sustainability /> }></Route>
          <Route path="/kurumsal-sorumluluk" element={ <Responsibility /> }></Route>
          <Route path="/kaliteli-bir-gelecek" element={ <Future /> }></Route>
          <Route path="/neler-yapildi" element={ <Done /> }></Route>
          <Route path="/kurumsal-sorumluluk/sanat" element={ <Art /> }></Route>
          <Route path="/kurumsal-sorumluluk/tarih" element={ <History /> }></Route>
          <Route path="/kurumsal-sorumluluk/spor" element={ <Sports /> }></Route>
          <Route path="/kurumsal-sorumluluk/cocuklar" element={ <Child /> }></Route>
          <Route path="/kurumsal-sorumluluk/gencler" element={ <Young /> }></Route>
          <Route path="/kurumsal-sorumluluk/kadinlar" element={ <Women /> }></Route>
          <Route path="/kurumsal-sorumluluk/toplum" element={ <Society /> }></Route>
          <Route path="/medya-merkezi" element={ <News /> }></Route> 
          <Route path="/haber/:Title/:ID" element={ <Article /> }></Route>
          <Route path="/kariyer" element={ <Career /> }></Route> 
          <Route path="/kariyer/ise-alim" element={ <Recruitment /> }></Route>
          <Route path="/kariyer/is-firsatlari" element={ <Opportunities /> }></Route>
          <Route path="/kariyer/degerlerimiz" element={ <Values /> }></Route>
          <Route path="/kariyer/sosyal-yasam" element={ <Social /> }></Route>
          <Route path="/kariyer/calisma-hayati" element={ <Working /> }></Route>
          <Route path="/kariyer/egitim-ve-gelisim" element={ <Education /> }></Route>
          <Route path="/kariyer/performans-yonetimi" element={ <Performance /> }></Route>
          <Route path="/kariyer/guvenli-calisma-ortami" element={ <Safety /> }></Route>
          <Route path="/kariyer/ucretlendirme" element={ <Price /> }></Route>
          <Route path="/kariyer/etik-kurallar" element={ <Ethics /> }></Route>
          <Route path="/arama" element={ <Search /> }></Route>
          <Route path="/iletisim" element={ <Contact /> }></Route>
          <Route path="*" element={ <Navigate to="/" /> } />
        </Routes>
      </BrowserRouter>
      <Footer></Footer>
    </div>
  );
}
//Element
export default App;
