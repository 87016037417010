//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";
//Install
import Parser from "html-react-parser";
//Install
import C827K5 from "../../../Media/Image/015.jpg";
//Install
import Breadcrumb from "../../Shortcode/Breadcrumb/Default";

//Element
class Default extends Component {
    //Element
    constructor(props){
        //Element
        super(props)
        //Element
        this.state = {
            //Element
            CZ524S : []
        }
    }
    //Element
    A00U45 = () => {
        //Element
        Axios.get( "https://static.benozgurtosun.com/board", {} )
        //Element
        .then( E6328N => {
            //Element
            this.setState({
                //Element
                CZ524S : E6328N.data
            })
        })
    }
    //Element
    EI13R9(){
        //Element
        let OM175Z     = document.querySelector( "meta[property='og:title']" )
        //Element
        let T00A3U     = document.querySelector( "meta[property='og:description']" )
        //Element
        let HU221M     = document.querySelector( "meta[property='og:image']" )
        //Element
        let V337GE     = document.querySelector( "meta[property='og:url']" )
        //Element
        OM175Z.content = "Yönetim Kurulu | Atasaylar Şirketler Grubu - Atasaylar.com.tr"
        //Element
        T00A3U.content = ""
        //Element
        HU221M.content = "https://atasaylar.com.tr/favicon.svg"
        //Element
        V337GE.content = "https://atasaylar.com.tr/yonetim-kurulu"
    }
    //Element
    Q8283L(){
        //Element
        let N09HE5     = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let D68001     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let N94MJ9     = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let S577FO     = document.querySelector( "meta[name='twitter:url']" )
        //Element
        N09HE5.content = "Yönetim Kurulu | Atasaylar Şirketler Grubu - Atasaylar.com.tr"
        //Element
        D68001.content = ""
        //Element
        N94MJ9.content = "https://atasaylar.com.tr/favicon.svg"
        //Element
        S577FO.content = "https://atasaylar.com.tr/yonetim-kurulu"
    }
    //Element
    N2782S(){
        //Element
        let AHF319     = document.querySelector( "meta[itemprop='name'" )
        //Element
        let W2LB44     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let K08577     = document.querySelector( "meta[itemprop='image']" )
        //Element
        AHF319.content = "Yönetim Kurulu | Atasaylar Şirketler Grubu - Atasaylar.com.tr"
        //Element
        W2LB44.content = ""
        //Element
        K08577.content = "https://atasaylar.com.tr/favicon.svg"
    }
    //Element
    E708TS(e){
        //Element
        e.preventDefault()
        //Element
        console.log( e.nativeEvent.target.nextElementSibling.classList.toggle( "d-none" ) )
    }
    //Element
    componentDidMount(){
        //Element
        document.title                                                                    = "Yönetim Kurulu | Atasaylar Şirketler Grubu - Atasaylar.com.tr"
        //Element
        document.getElementsByTagName( "meta" )["description"].content                    = ""
        //Element
        document.querySelectorAll( "link[rel='canonical']" )[0].setAttribute( "href", "https://atasaylar.com.tr/yonetim-kurulu" )
        //Element
        this.EI13R9()
        //Element
        this.Q8283L()
        //Element
        this.N2782S()
        //Element
        this.A00U45()
    }
    //Element
    render() {
        //Element
        let DW203C = "https://static.benozgurtosun.com/images/"
        //Element
        let MB337R = " - "
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 dl4n30" },
            //Element
            React.createElement( Breadcrumb, { ID: "0", Title: "Kurumsal", Sub: "Yönetim Kurulu", Target: "/yonetim-kurulu" } ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 ev804z" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 m3h8a4" },
                                //Element
                                React.createElement( "figure", { className: "float-start w-100 p-0 m-0 b58910", style: { backgroundImage: "url("+ C827K5 +")" } } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 d4dx67" },
                                //Element
                                React.createElement( "h1", {},
                                    //Element
                                    "Yönetim Kurulu"
                                ),
                                //Element
                                React.createElement( "p", {},
                                    //Element
                                    "Atasaylar Grubu, profesyonel yönetim yaklaşımı kapsamında kurumsal yönetim ilkelerine öncelik ve önem vermektedir. Bu sebeple, hissedarları arasında fark gözetmez ve bütün faaliyet alanlarında etik kurallara uygun biçimde hareket eder."
                                ),
                                //Element
                                React.createElement( "ol", { className: "float-start w-100 p-0 m-0 mt-4 cw764m" },
                                    //Element
                                    this.state.CZ524S.map( (X5V402) => {
                                        //Element
                                        return React.createElement( "li", { key: X5V402.ID, className: "float-start w-100 p-0 m-0 mb-2", onClick: this.E708TS },
                                            //Element
                                            React.createElement( "a", { className: "float-start w-100 p-0 ps-4 pe-4 m-0", href: "#Board" },
                                                //Element
                                                X5V402.Title + MB337R + X5V402.Position
                                            ),
                                            //Element
                                            React.createElement( "label", { className: "float-start w-100 p-0 ps-4 pe-4 pt-3 pb-3 m-0 mb-1 d-none un78b0" },
                                                //Element
                                                X5V402.Content
                                            )
                                        )
                                    })
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;