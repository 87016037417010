//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";
//Install
import Parser from "html-react-parser";
//Install
import Breadcrumb from "../../Shortcode/Breadcrumb/Default";

//Element
class Default extends Component {
    //Element
    constructor(props){
        //Element
        super(props)
        //Element
        this.state = {
            //Element
            CZ524S : []
        }
    }
    //Element
    A00U45 = () => {
        //Element
        Axios.get( "https://static.benozgurtosun.com/responsibility", {} )
        //Element
        .then( E6328N => {
            //Element
            this.setState({
                //Element
                CZ524S : E6328N.data
            })
        })
    }
    //Element
    EI13R9(){
        //Element
        let OM175Z     = document.querySelector( "meta[property='og:title']" )
        //Element
        let T00A3U     = document.querySelector( "meta[property='og:description']" )
        //Element
        let HU221M     = document.querySelector( "meta[property='og:image']" )
        //Element
        let V337GE     = document.querySelector( "meta[property='og:url']" )
        //Element
        OM175Z.content = "Kaliteli Bir Gelecek | Atasaylar Şirketler Grubu - Atasaylar.com.tr"
        //Element
        T00A3U.content = ""
        //Element
        HU221M.content = "https://atasaylar.com.tr/favicon.svg"
        //Element
        V337GE.content = "https://atasaylar.com.tr/kaliteli-bir-gelecek"
    }
    //Element
    Q8283L(){
        //Element
        let N09HE5     = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let D68001     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let N94MJ9     = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let S577FO     = document.querySelector( "meta[name='twitter:url']" )
        //Element
        N09HE5.content = "Kaliteli Bir Gelecek | Atasaylar Şirketler Grubu - Atasaylar.com.tr"
        //Element
        D68001.content = ""
        //Element
        N94MJ9.content = "https://atasaylar.com.tr/favicon.svg"
        //Element
        S577FO.content = "https://atasaylar.com.tr/kaliteli-bir-gelecek"
    }
    //Element
    N2782S(){
        //Element
        let AHF319     = document.querySelector( "meta[itemprop='name'" )
        //Element
        let W2LB44     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let K08577     = document.querySelector( "meta[itemprop='image']" )
        //Element
        AHF319.content = "Kaliteli Bir Gelecek | Atasaylar Şirketler Grubu - Atasaylar.com.tr"
        //Element
        W2LB44.content = ""
        //Element
        K08577.content = "https://atasaylar.com.tr/favicon.svg"
    }
    //Element
    componentDidMount(){
        //Element
        document.title                                                                    = "Kaliteli Bir Gelecek | Atasaylar Şirketler Grubu - Atasaylar.com.tr"
        //Element
        document.getElementsByTagName( "meta" )["description"].content                    = ""
        //Element
        document.querySelectorAll( "link[rel='canonical']" )[0].setAttribute( "href", "https://atasaylar.com.tr/kaliteli-bir-gelecek" )
        //Element
        this.EI13R9()
        //Element
        this.Q8283L()
        //Element
        this.N2782S()
        //Element
        this.A00U45()
    }
    //Element
    render() {
        //Element
        let DW203C = "https://static.benozgurtosun.com/images/"
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 dl4n30" },
            //Element
            React.createElement( Breadcrumb, { ID: "0", Title: "Kurumsal Sorumluluk", Sub: "Kaliteli Bir Gelecek", Target: "/kaliteli-bir-gelecek" } ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 ev804z" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 m3h8a4" },
                                //Element
                                this.state.CZ524S.map( (X5V402) => {
                                    //Element
                                    return React.createElement( "figure", { key: X5V402.ID, className: "float-start w-100 p-0 m-0 b58910", style: { backgroundImage: "url("+ DW203C + X5V402.Image +")" } } )
                                })
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 d4dx67" },
                                //Element
                                this.state.CZ524S.map( (X5V402) => {
                                    //Element
                                    return React.createElement( "h1", { key: X5V402.ID },
                                        //Element
                                        X5V402.Title
                                    )
                                }),
                                //Element
                                this.state.CZ524S.map( (X5V402) => {
                                    //Element
                                    return Parser( X5V402.Content )
                                })
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;