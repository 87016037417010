//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col, TabContent } from "react-bootstrap";
//Install
import Half from "../../Shortcode/Tab/Half/Default";
//Install
import Quarter from "../../Shortcode/Tab/Quarter/Default";
//Install
import Breadcrumb from "../../Shortcode/Breadcrumb/Default";

//Element
class Showcase extends Component {
    //Element
    EI13R9(){
        //Element
        let OM175Z     = document.querySelector( "meta[property='og:title']" )
        //Element
        let T00A3U     = document.querySelector( "meta[property='og:description']" )
        //Element
        let HU221M     = document.querySelector( "meta[property='og:image']" )
        //Element
        let V337GE     = document.querySelector( "meta[property='og:url']" )
        //Element
        OM175Z.content = "Kurumsal Sorumluluk | Atasaylar Şirketler Grubu - Atasaylar.com.tr"
        //Element
        T00A3U.content = ""
        //Element
        HU221M.content = "https://atasaylar.com.tr/favicon.svg"
        //Element
        V337GE.content = "https://atasaylar.com.tr/kurumsal-sorumluluk"
    }
    //Element
    Q8283L(){
        //Element
        let N09HE5     = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let D68001     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let N94MJ9     = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let S577FO     = document.querySelector( "meta[name='twitter:url']" )
        //Element
        N09HE5.content = "Kurumsal Sorumluluk | Atasaylar Şirketler Grubu - Atasaylar.com.tr"
        //Element
        D68001.content = ""
        //Element
        N94MJ9.content = "https://atasaylar.com.tr/favicon.svg"
        //Element
        S577FO.content = "https://atasaylar.com.tr/kurumsal-sorumluluk"
    }
    //Element
    N2782S(){
        //Element
        let AHF319     = document.querySelector( "meta[itemprop='name'" )
        //Element
        let W2LB44     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let K08577     = document.querySelector( "meta[itemprop='image']" )
        //Element
        AHF319.content = "Kurumsal Sorumluluk | Atasaylar Şirketler Grubu - Atasaylar.com.tr"
        //Element
        W2LB44.content = ""
        //Element
        K08577.content = "https://atasaylar.com.tr/favicon.svg"
    }
    //Element
    Q5FB85 = () => {
        //Element
        let U47K46 = document.getElementsByClassName( "iw862h" )[0]
        //Element
        let GPX789 = document.getElementsByClassName( "o8f41v" )[0]
        //Element
        U47K46.classList.remove( "mt-5" )
        //Element
        GPX789.classList.add( "border-0" )
    }
    //Element
    componentDidMount(){
        //Element
        document.title                                                                    = "Kurumsal Sorumluluk | Atasaylar Şirketler Grubu - Atasaylar.com.tr"
        //Element
        document.getElementsByTagName( "meta" )["description"].content                    = ""
        //Element
        document.querySelectorAll( "link[rel='canonical']" )[0].setAttribute( "href", "https://atasaylar.com.tr/kurumsal-sorumluluk" )
        //Element
        this.EI13R9()
        //Element
        this.Q8283L()
        //Element
        this.N2782S()
        //Element
        this.Q5FB85()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 dl4n30" },
            //Element
            React.createElement( Breadcrumb, { ID: "0", Title: "Kurumsal Sorumluluk", Sub: "Genel Bilgilendirme", Target: "/kurumsal-sorumluluk" } ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 d43c17" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 pt-5 m-0 mt-5 qz89u8" },
                                //Element
                                React.createElement( "h1", { className: "float-start w-auto p-0 m-0 d588t7" },
                                    //Element
                                    "Kurumsal Sorumluluk"
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 mt-5 p0k84o" },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 h19214" },
                    //Element
                    React.createElement( Half, { ID: "0", Title: "Kaliteli Bir Gelecek", Media: "032.jpg", Target: "/kaliteli-bir-gelecek" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 h19214" },
                    //Element
                    React.createElement( Half, { ID: "0", Title: "Neler Yapıldı?", Media: "033.jpg", Target: "/neler-yapildi" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 xn254h" },
                    //Element
                    React.createElement( Quarter, { ID: "0", Title: "Sanat'a Gelecek", Media: "024.jpg", Target: "/kurumsal-sorumluluk/sanat" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 xn254h" },
                    //Element
                    React.createElement( Quarter, { ID: "0", Title: "Tarih'e Gelecek", Media: "025.jpg", Target: "/kurumsal-sorumluluk/tarih" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 xn254h" },
                    //Element
                    React.createElement( Quarter, { ID: "0", Title: "Spor'a Gelecek", Media: "031.jpg", Target: "/kurumsal-sorumluluk/spor" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 xn254h" },
                    //Element
                    React.createElement( Quarter, { ID: "0", Title: "Çocuklar'a Gelecek", Media: "026.jpg", Target: "/kurumsal-sorumluluk/cocuklar" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 xn254h" },
                    //Element
                    React.createElement( Quarter, { ID: "0", Title: "Gençler'e Gelecek", Media: "027.jpg", Target: "/kurumsal-sorumluluk/gencler" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 xn254h" },
                    //Element
                    React.createElement( Quarter, { ID: "0", Title: "Kadınlar'a Gelecek", Media: "028.jpg", Target: "/kurumsal-sorumluluk/kadinlar" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 xn254h" },
                    //Element
                    React.createElement( Quarter, { ID: "0", Title: "Toplum'a Gelecek", Media: "030.jpg", Target: "/kurumsal-sorumluluk/toplum" } )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Showcase;