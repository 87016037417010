//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";
//Install
import Menu from "./Footer/Menu";
//Install
import Social from "./Footer/Social";
//Install
import Submenu from "./Footer/Submenu";
//Install
import Copyright from "./Footer/Copyright";

//Element
class Footer extends Component {
    //Element
    constructor(props){
        //Element
        super(props)
        //Element
        this.state = {
            //Element
            C4223G : []
        }
    }
    //Element
    G30D4G = () => {
        //Element
        Axios.get( "https://static.benozgurtosun.com/social", {} )
        //Element
        .then( E6328N => {
            //Element
            this.setState({
                //Element
                C4223G : E6328N.data
            })
        })
    }
    //Element
    componentDidMount(){
        //Element
        this.G30D4G()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "footer", { className: "float-start w-100 p-0 m-0 w71b4c" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 iw862h" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            this.state.C4223G.map( (T3RJ69) => {
                                //Element
                                return React.createElement( Social, { key: T3RJ69.ID, ID: T3RJ69.ID.toString(), Facebook: T3RJ69.Facebook, X: T3RJ69.X, Instagram: T3RJ69.Instagram, YouTube: T3RJ69.Youtube, Linkedin: T3RJ69.Linkedin } )
                            })
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 k504o1" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 bt16w5" },
                                //Element
                                React.createElement( Copyright, { ID: "0", Title: "© 2024 Atasaylar Şirketler Grubu A.Ş. Tüm Hakları Saklıdır." } ),
                                //Element
                                React.createElement( Menu, {} )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 position-fixed d-none s6679h" },
                //Element
                React.createElement( Submenu, {} )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Footer;