//Install
import React, { Component } from "react";
//Install
import Axios from "axios";

//Element
class Values extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-start p-0 ps-5 pe-5 m-0 q18m0p" },
            //Element
            React.createElement( "p", { className: "float-start w-auto p-0 m-0 rl900u" },
                //Element
                "Atasaylar Grubu, kuruluşundan bu yana birlik ve bütünlük, anlayış, mükemmellik, sorumluluk ve yaratıcılık gibi temel değerleri iş kültürünün merkezine yerleştirmiştir."
            ),
            //Element
            React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-3 rl900u" },
                //Element
                "Bu değerler, her günkü iş süreçlerine rehberlik etmektedir."
            ),
            //Element
            React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-3 rl900u" },
                //Element
                "Aynı zamanda, uzun vadeli stratejik hedeflerin belirlenmesinde de önemli bir rol oynamaktadır. Grup şirketlerinde alınan her karar, bu ilkeler doğrultusunda şekillenir."
            ),
            //Element
            React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-3 rl900u" },
                //Element
                "Böylece, sürdürülebilir başarıya ulaşma hedefi sürekli olarak desteklenir."
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Values;