//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";
//Install
import News from "./Content/News/Default";
//Install
import Sector from "./Content/Sector/Default";
//Install
import Career from "./Content/Career/Default";
//Install
import Counter from "./Content/Counter/Default";
//Install
import Governance from "./Content/Governance/Default";
//Install
import Corporation from "./Content/Corporation/Default";
//Install
import Sustainability from "./Content/Sustainability/Default";

//Element
class Main extends Component {
    //Element
    constructor(props){
        //Element
        super(props)
        //Element
        this.state = {
            //Element
            IJ947I : []
        }
    }
    //Element
    PS1N29 = () => {
        //Element
        Axios.get( "https://static.benozgurtosun.com/setting", {} )
        //Element
        .then( E6328N => {
            //Element
            this.setState({
                //Element
                IJ947I : E6328N.data
            })
        })
    }
    //Element
    EI13R9(){
        //Element
        let OM175Z     = document.querySelector( "meta[property='og:title']" )
        //Element
        let T00A3U     = document.querySelector( "meta[property='og:description']" )
        //Element
        let HU221M     = document.querySelector( "meta[property='og:image']" )
        //Element
        let V337GE     = document.querySelector( "meta[property='og:url']" )
        //Element
        OM175Z.content = "Anasayfa | Atasaylar Şirketler Grubu - Atasaylar.com.tr"
        //Element
        T00A3U.content = ""
        //Element
        HU221M.content = "https://atasaylar.com.tr/favicon.svg"
        //Element
        V337GE.content = "https://atasaylar.com.tr/"
    }
    //Element
    Q8283L(){
        //Element
        let N09HE5     = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let D68001     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let N94MJ9     = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let S577FO     = document.querySelector( "meta[name='twitter:url']" )
        //Element
        N09HE5.content = "Anasayfa | Atasaylar Şirketler Grubu - Atasaylar.com.tr"
        //Element
        D68001.content = ""
        //Element
        N94MJ9.content = "https://atasaylar.com.tr/favicon.svg"
        //Element
        S577FO.content = "https://atasaylar.com.tr/"
    }
    //Element
    N2782S(){
        //Element
        let AHF319     = document.querySelector( "meta[itemprop='name'" )
        //Element
        let W2LB44     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let K08577     = document.querySelector( "meta[itemprop='image']" )
        //Element
        AHF319.content = "Anasayfa | Atasaylar Şirketler Grubu - Atasaylar.com.tr"
        //Element
        W2LB44.content = ""
        //Element
        K08577.content = "https://atasaylar.com.tr/favicon.svg"
    }
    //Element
    componentDidMount(){
        //Element
        document.title                                                                    = "Anasayfa | Atasaylar Şirketler Grubu - Atasaylar.com.tr"
        //Element
        document.getElementsByTagName( "meta" )["description"].content                    = ""
        //Element
        document.querySelectorAll( "link[rel='canonical']" )[0].setAttribute( "href", "https://atasaylar.com.tr/" )
        //Element
        this.EI13R9()
        //Element
        this.Q8283L()
        //Element
        this.N2782S()
        //Element
        this.PS1N29()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 h3947e" },
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 og976r" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            this.state.IJ947I.map( (J7K70O) => {
                                //Element
                                return React.createElement( Counter, { key: J7K70O.ID, ID: J7K70O.ID.toString(), Title: "Farklı Sektör'de", Number: J7K70O.Sector, Target: "/" } )
                            })
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            this.state.IJ947I.map( (J7K70O) => {
                                //Element
                                return React.createElement( Counter, { key: J7K70O.ID, ID: J7K70O.ID.toString(), Title: "Güçlü Şirket", Number: J7K70O.Company, Target: "/" } )
                            })
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            ///Element
                            this.state.IJ947I.map( (J7K70O) => {
                                //Element
                                return React.createElement( Counter, { key: J7K70O.ID, ID: J7K70O.ID.toString(), Title: "Lider Marka", Number: J7K70O.Brand, Target: "/" } )
                            })
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 me222n" },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 i90wo5" },
                    //Element
                    React.createElement( Corporation, { ID: "0", Title: "Atasaylar Grubu'nu Tanıyın", Summary: "", Target: "/kurumsal" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 i90wo5" },
                    //Element
                    React.createElement( Sector, { ID: "0", Title: "Sektörler", Summary: "", Target: "/faaliyet-alanlari" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 d7n8s0" },
                    //Element
                    React.createElement( Career, { ID: "0", Title: "Kariyer Fırsatları", Summary: "", Target: "/kariyer" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 d7n8s0" },
                    //Element
                    React.createElement( Governance, { ID: "0", Title: "Kurumsal Sorumluluk", Summary: "", Target: "/kurumsal-sorumluluk" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 d7n8s0" },
                    //Element
                    React.createElement( News, { ID: "0", Title: "Basın Odası", Summary: "", Target: "/medya-merkezi" } )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 rv3679" },
                //Element
                React.createElement( Sustainability, { ID: "0", Title: "Beraber Doğanın Geleceği İçin Çalışmak", Summary: "Daha Kaliteli Bir Gelecek, Hâlâ Elde Edilebilir.", Media: "", Target: "/surdurulebilirlik" } )
            )
        )   
        //Element
        return (
            Content
        );
    }
}
//Element
export default Main;