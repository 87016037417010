//Install
import React, { useState, useEffect } from "react";
//Install
import { useParams } from "react-router-dom";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";
//Install
import Parser from "html-react-parser";
//Install
import SEO from "../../Function/SEO";
//Install
import Breadcrumb from "../../Shortcode/Breadcrumb/Default";

//Element
function Y26F5M(){
    //Element
    const { ID } = useParams()
    //Element
    let Z1K17M = " | Haberler ve Bülten - Atasaylar.com.tr"
    //Element
    useEffect( () => {
        //Element
        const G566D6 = async() => {
            //Element
            const V32Z4C = await Axios.get( "https://static.benozgurtosun.com/news/select", {
                //Element
                params : {
                    //Element
                    ID : ID
                }
            })
            //Element
            .then( C16J5B => {
                //Element
                let OM175Z     = document.querySelector( "meta[property='og:title']" )
                //Element
                let T00A3U     = document.querySelector( "meta[property='og:description']" )
                //Element
                let HU221M     = document.querySelector( "meta[property='og:image']" )
                //Element
                let V337GE     = document.querySelector( "meta[property='og:url']" )
                //Element
                OM175Z.content = C16J5B.data[0].Title + Z1K17M
                //Element
                T00A3U.content = C16J5B.data[0].Summary
                //Element
                HU221M.content = "https://atasaylar.com.tr/favicon.svg"
                //Element
                V337GE.content = window.location.href
                //Element
                let N09HE5     = document.querySelector( "meta[name='twitter:title']" )
                //Element
                let D68001     = document.querySelector( "meta[name='twitter:description']" )
                //Element
                let N94MJ9     = document.querySelector( "meta[name='twitter:image']" )
                //Element
                let S577FO     = document.querySelector( "meta[name='twitter:url']" )
                //Element
                N09HE5.content = C16J5B.data[0].Title + Z1K17M
                //Element
                D68001.content = C16J5B.data[0].Summary
                //Element
                N94MJ9.content = "https://atasaylar.com.tr/favicon.svg"
                //Element
                S577FO.content = window.location.href
                //Element
                let AHF319     = document.querySelector( "meta[itemprop='name'" )
                //Element
                let W2LB44     = document.querySelector( "meta[itemprop='description']" )
                //Element
                let K08577     = document.querySelector( "meta[itemprop='image']" )
                //Element
                AHF319.content = C16J5B.data[0].Title + Z1K17M
                //Element
                W2LB44.content = C16J5B.data[0].Summary
                //Element
                K08577.content = "https://atasaylar.com.tr/favicon.svg"
                //Element
                document.title = C16J5B.data[0].Title + Z1K17M
                //Element
                document.getElementsByTagName( "meta" )["description"].content = C16J5B.data[0].Summary
                //Element
                document.querySelectorAll( "link[rel='canonical']" )[0].setAttribute( "href", window.location.href )
            })
        }
        //Element
        G566D6()
    })
}
//Element
function Article(){
    //Element
    Y26F5M()
    //Element
    const { ID } = useParams()
    //Element
    let DW203C = "https://static.benozgurtosun.com/images/"
    //Element
    const [ SQ419L, E3ZZ97 ] = React.useState([])
    //Element
    const [ E38142, I3N71A ] = React.useState([])
    //Element
    useEffect( () => {
        //Element
        const G566D6 = async() => {
            //Element
            const V32Z4C = await Axios.get( "https://static.benozgurtosun.com/news/select", {
                //Element
                params : {
                    //Element
                    ID : ID
                }
            })
            //Element
            E3ZZ97( V32Z4C.data )
        }
        //Element
        G566D6()
    }, [] )
    //Element
    const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 dl4n30" },
        //Element
        SQ419L.map( (T505UT) => {
            //Element
            return React.createElement( Breadcrumb, { key: T505UT.ID, ID: T505UT.ID.toString(), Title: "Haberler ve Bültenler", Sub: T505UT.Title, Target: "/medya-merkezi" } )
        }),
        //Element
        React.createElement( "section", { className: "float-start w-100 p-0 m-0 ev804z" },
            //Element
            React.createElement( Container, {},
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:6 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 m3h8a4" },
                            //Element
                            SQ419L.map( (X5V402) => {
                                //Element
                                return React.createElement( "figure", { key: X5V402.ID, className: "float-start w-100 p-0 m-0 b58910", style: { backgroundImage: "url("+ DW203C + X5V402.Image +")" } } )
                            })
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:6 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 d4dx67" },
                            //Element
                            SQ419L.map( (X5V402) => {
                                //Element
                                return React.createElement( "h1", { key: X5V402.ID },
                                    //Element
                                    X5V402.Title
                                )
                            }),
                            //Element
                            SQ419L.map( (X5V402) => {
                                //Element
                                return React.createElement( "strong", {},
                                    //Element
                                    Parser( X5V402.Summary )
                                )
                            }),
                            //Element
                            SQ419L.map( (X5V402) => {
                                //Element
                                return Parser( X5V402.Content )
                            })
                        )
                    )
                )
            )
        )
    )
    //Element
    return(
        Content
    )
}
//Element
export default Article;