//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { ReactComponent as XX5716 } from "../../../Media/Icon/006.svg";
//Install
import propTypes from "prop-types";

//Element
class Default extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Sub, Target } = this.props
        //Element
        const Content = React.createElement( "section", { className: "float-start w-100 p-0 m-0 qzc306" },
            //Element
            React.createElement( Container, {},
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "menu", { className: "float-start w-100 p-0 m-0 d97b9m" },
                            //Element
                            React.createElement( "li", { className: "float-start w-auto" },
                                //Element
                                React.createElement( "a", { className: "float-start w-auto p-0 m-0", href: "/" },
                                    //Element
                                    "Başlangıç"
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-auto" },
                                //Element
                                React.createElement( "span", { className: "float-start w-auto p-0 ps-1 m-0 ms-2" },
                                    //Element
                                    React.createElement( XX5716, {} )
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-auto" },
                                //Element
                                React.createElement( "a", { className: "float-start w-auto p-0 m-0 ms-3", href: Target },
                                    //Element
                                    Title
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-auto" },
                                //Element
                                React.createElement( "span", { className: "float-start w-auto p-0 ps-1 m-0 ms-2" },
                                    //Element
                                    React.createElement( XX5716, {} )
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-auto" },
                                //Element
                                React.createElement( "label", { className: "float-start w-auto p-0 m-0 ms-3 text-capitalize" },
                                    //Element
                                    Sub
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Default.propTypes = {
    ID     : propTypes.string.isRequired,
    Title  : propTypes.string.isRequired,
    Sub    : propTypes.string.isRequired,
    Target : propTypes.string.isRequired,
}
//Element
export default Default;