//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";
//Install
import SEO from "../../Function/SEO";
//Install
import Article from "../../Shortcode/Card/Search";
//Install
import Breadcrumb from "../../Shortcode/Breadcrumb/Default";

//Element
class Default extends Component {
    //Element
    constructor(props){
        //Element
        super(props)
        //Element
        this.state = {
            //Element
            CZ524S : []
        }
    }
    //Element
    A00U45 = () => {
        //Element
        let P36R26 = window.location.search
        //Element
        let W4Z7O7 = new URLSearchParams( P36R26 )
        //Element
        let Y320XZ = W4Z7O7.get( "q" )
        //Element
        const QI633Y = async() => {
            //Element
            const J9LF15 = await Axios.get( "https://static.benozgurtosun.com/search", {
                //Element
                params : {
                    //Element
                    Like : Y320XZ
                }
            })
            //Element
            .then( D445FN => {
                //Element
                if( D445FN.data.length != 0 ){
                    //Element
                    this.setState({
                        //Element
                        CZ524S : D445FN.data
                    })
                }
            })
        }
        //Element
        QI633Y()
    }
    //Element
    EI13R9(){
        //Element
        let OM175Z     = document.querySelector( "meta[property='og:title']" )
        //Element
        let T00A3U     = document.querySelector( "meta[property='og:description']" )
        //Element
        let HU221M     = document.querySelector( "meta[property='og:image']" )
        //Element
        let V337GE     = document.querySelector( "meta[property='og:url']" )
        //Element
        OM175Z.content = "Arama | Atasaylar Şirketler Grubu - Atasaylar.com.tr"
        //Element
        T00A3U.content = ""
        //Element
        HU221M.content = "https://atasaylar.com.tr/favicon.svg"
        //Element
        V337GE.content = "https://atasaylar.com.tr/"
    }
    //Element
    Q8283L(){
        //Element
        let N09HE5     = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let D68001     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let N94MJ9     = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let S577FO     = document.querySelector( "meta[name='twitter:url']" )
        //Element
        N09HE5.content = "Arama | Atasaylar Şirketler Grubu - Atasaylar.com.tr"
        //Element
        D68001.content = ""
        //Element
        N94MJ9.content = "https://atasaylar.com.tr/favicon.svg"
        //Element
        S577FO.content = "https://atasaylar.com.tr/"
    }
    //Element
    N2782S(){
        //Element
        let AHF319     = document.querySelector( "meta[itemprop='name'" )
        //Element
        let W2LB44     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let K08577     = document.querySelector( "meta[itemprop='image']" )
        //Element
        AHF319.content = "Arama | Atasaylar Şirketler Grubu - Atasaylar.com.tr"
        //Element
        W2LB44.content = ""
        //Element
        K08577.content = "https://atasaylar.com.tr/favicon.svg"
    }
    //Element
    componentDidMount(){
        //Element
        document.title                                                                    = "Arama | Atasaylar Şirketler Grubu - Atasaylar.com.tr"
        //Element
        document.getElementsByTagName( "meta" )["description"].content                    = ""
        //Element
        document.querySelectorAll( "link[rel='canonical']" )[0].setAttribute( "href", "https://atasaylar.com.tr/" )
        //Element
        this.EI13R9()
        //Element
        this.Q8283L()
        //Element
        this.N2782S()
        //Element
        this.A00U45()
    }
    //Element
    render() {
        //Element
        let P36R26 = window.location.search
        //Element
        let W4Z7O7 = new URLSearchParams( P36R26 )
        //Element
        let Y320XZ = W4Z7O7.get( "q" )
        //Element
        if( Y320XZ != null ){
            //Element
        //Element
        } else {
            //Element
            window.location.href = "/"
        }
        //Element
        let G300T6 = "/haber/"
        //Element
        let B366VA = "/"
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 dl4n30" },
            //Element
            React.createElement( Breadcrumb, { ID: "0", Title: "Arama", Sub: Y320XZ, Target: "/arama/" } ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 ev804z" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 m3h8a4" },
                                //Element
                                React.createElement( "figure", { className: "float-start w-100 p-0 m-0 q27n1h" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 mb-3 q03e4u" },
                                //Element
                                this.state.CZ524S.map( (X5V402) => {
                                    //Element
                                    return React.createElement( Article, { key: X5V402.ID, ID: X5V402.ID.toString(), Title: X5V402.Title, Time: X5V402.Date, Target: G300T6 + SEO(X5V402.Title) + B366VA + X5V402.ID } )
                                })
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;