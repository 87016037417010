//Install
import React, { Component } from "react";
//Install
import { ReactComponent as YE33B6 } from "../../../Media/Icon/005.svg";

//Element
class Default extends Component {
    //Element
    QIW477 = (e) => {
        //Element
        e.preventDefault()
        //Element
        let FU096Q = "/arama/?q="
        //Element
        let G7824T = document.getElementById( "Web_V920O1" ).value
        //Element
        window.location.href = FU096Q + G7824T
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "form", { className: "float-start w-100 p-0 m-0 b51403", method: "GET", onSubmit: this.QIW477 },
            //Element
            React.createElement( "input", { id: "Web_V920O1", name: "Web_V920O1", className: "float-start w-100 p-0 m-0 x98d3z", type: "text", placeholder: "Arama...", minLength: "3", required: "required" } ),
            //Element
            React.createElement( "button", { id: "Web_MV659J", name: "Web_MV659J", className: "float-start w-100 p-0 m-0 position-absolute end-0 top-0 m0a9d9", type: "button" },
                //Element
                React.createElement( YE33B6, {} )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;